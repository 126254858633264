import { Injectable } from '@angular/core';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation } from '@chart/charting_library';
import { StorageKeys, TradePositions } from '@const';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import * as _ from 'lodash';
import moment from 'moment';

@Injectable()
export class ProfitLoss {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createProfitLoss(
    PineJS,
    isFirstChart,
    observableService: ObservableService,
    localStorageService: LocalStorageService,
  ) {
    const name = isFirstChart ? 'FirstProfitLoss' : 'SecondProfitLoss';
    return {
      name,
      metainfo: {
        _metainfoVersion: 40,
        id: `${name}@tv-basicstudies-1` as RawStudyMetaInfoId,
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 2,
        },
        scriptIdPart: '',
        name,
        description: name,
        shortDescription: `Profit & Loss (#${isFirstChart ? 1 : 2})`,
        is_hidden_study: true,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
          { id: 'plot_0', type: 'line' },
          { id: 'plot_1', type: 'colorer', palette: 'palette_0', target: 'plot_0' },
        ] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        palettes: {
          palette_0: {
            colors: [{ name: 'Color 0' }, { name: 'Color 1' }],

            valToIndex: {
              0: 0,
              1: 1,
            },
          },
        },
        defaults: {
          styles: {
            plot_0: {
              isHidden: true,
              linestyle: 0,
              visible: true,
              linewidth: 2,
              plottype: 4,
              trackPrice: false,
              transparency: 20,
              // color: '#2196F3' // blue color
            },
          },
          palettes: {
            palette_0: {
              colors: {
                0: {
                  color: '#F3B2AF', // red
                  width: 1,
                  style: 0,
                },
                1: {
                  color: '#A3D8D2', // green
                  width: 1,
                  style: 0,
                },
              },
            },
          },
          // precision: 0, // Precision is set to one digit, e.g. 777.7
          inputs: {
            isHidden: true,
          },
        },

        styles: {
          plot_0: {
            title: 'title', // Output name will be displayed in the Style window
            histogramBase: 1,
            isHidden: true,
          },
        },
        inputs: [],
      },

      constructor: function (): void {
        this.init = async function (): Promise<void> {
          this.result = [];
          this.runningTotal = 0;

          this.tradePosition = observableService.tradePosition.getValue();
          const security_id = observableService.symbol.getValue();
          const strategy_id = isFirstChart
            ? observableService.firstTradingStrategyId.getValue()
            : observableService.secondTradingStrategyId.getValue();

          const processedStorageKey = `${StorageKeys.ProcessedData}_${security_id}`;
          this.processedData = localStorageService.getFromMemory(processedStorageKey);
          const storageKey = `${StorageKeys.TradeReport}_${security_id}_${strategy_id}_${false}_${true}`;
          this.tradeReport = localStorageService.getFromMemory(storageKey);
          this.tradeReportWithPointsData = {};
          if (this.tradeReport && this.processedData) {
            _.toArray(this.tradeReport)
              .filter((report) => {
                if (report?.bar_exit_date) {
                  return this.processedData[report.bar_exit_date];
                }
              })
              .map((report) => {
                if (
                  report?.exit_date &&
                  (this.tradePosition === TradePositions.LongAndShort || report.position === this.tradePosition)
                ) {
                  this.runningTotal += report.total_profit;
                  this.tradeReportWithPointsData[report.bar_exit_date] = {
                    pointData: [this.runningTotal, this.runningTotal >= 0 ? 1 : 0],
                  };
                }
              });
          }
        };

        this.main = function (context): unknown {
          const barDate = moment(PineJS.Std.time(context)).tz('America/New_York').format('YYYY-MM-DD');
          const tradeData = this.tradeReportWithPointsData;

          if (!this.result?.length && !tradeData[barDate]) {
            const availableDates = Object.keys(tradeData).filter((date) => moment(date).isBefore(barDate));

            if (availableDates.length === 0) {
              return;
            }

            const closestPastDate = availableDates.reduce((prev, curr) => (moment(curr).isAfter(prev) ? curr : prev));

            this.result = tradeData[closestPastDate]?.pointData || [];
            return this.result;
          }

          this.result = tradeData[barDate]?.pointData || this.result;
          return this.result;
        };
      },
    };
  }
}
