/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@c/snack-bar/snack-bar.component';
import {
  ChartHistoryIntervalMonths,
  ChartIntervals,
  ChartSaveData,
  Colors,
  EasternTimeZoneName,
  ExchangeCountriesCodes,
  MinStockPriceLevel,
  ShowEntryAndExitOptions,
  ShowTradeOptions,
  StorageKeys,
  TabletMinWidth,
  TechnicalIndicators,
  Themes,
  TradePositions,
  UserSettings,
  getShowEntryAndExitOptionName,
  getShowTradeOptionName,
  getTradeEntryChartObject,
  getTradeExitChartObject,
} from '@const';
import { BarColor } from '@core/business/trading-chart/bar-color';
import { Macd } from '@core/business/trading-chart/macd';
import { MacdSignal } from '@core/business/trading-chart/macd-signal';
import { MacdValue } from '@core/business/trading-chart/macd-value';
import { ProfitLoss } from '@core/business/trading-chart/profit-loss';
import { Rsi } from '@core/business/trading-chart/rsi';
import { RSIValue } from '@core/business/trading-chart/rsi-value';
import { Stochastic } from '@core/business/trading-chart/stochastic';
import { StochasticValue } from '@core/business/trading-chart/stochastic-value';
import { IChartSaveData, IMarketData } from '@core/types';
import { ExchangeModel } from '@mod/data/exchange.model';
import { ExchangesService } from '@s/exchanges.service';
import { LocalStorageService } from '@s/local-storage.service';
import { MarketTimeService } from '@s/market-time.service';
import { ObservableService } from '@s/observable.service';
import { PastPerformanceService } from '@s/past-performance.service';
import { ProcessedDataService } from '@s/processed-data.service';
import { StreamingService } from '@s/streaming.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradeReportService } from '@s/trade-report.service';
import { TradingChartService } from '@s/trading-chart.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject, Subscriber } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import {
  ChartingLibraryWidgetOptions,
  DatafeedConfiguration,
  EntityId,
  IBasicDataFeed,
  IChartWidgetApi,
  IChartingLibraryWidget,
  ResolutionString,
  SetVisibleTimeRange,
  widget,
} from 'src/assets/charting_library';

interface ISaveChartShapes {
  symbolId: number;
  state: { shapes: Array<Record<string, any>>; isError: boolean };
}

@Component({
  selector: 'app-trading-chart',
  templateUrl: './trading-chart.component.html',
  styleUrls: ['./trading-chart.component.css'],
})
export class TradingChartComponent implements OnInit, OnDestroy {
  private saveChartShapesSubject = new Subject<ISaveChartShapes>();
  private listenToDrawEvent = true;
  private lastSavedShapesState: Array<Record<string, any>> | null = null;
  private subscriber = new Subscriber();
  private tvWidget: IChartingLibraryWidget | null = null;
  private activeChart: IChartWidgetApi | null = null;
  private _exchange: ExchangeModel = null;
  private showEntryAndExitOption: string;
  private showTradeOption: string;
  private showTechnicalIndicatorsOption: string;
  private currentVisibleRange: SetVisibleTimeRange = {
    from: moment().subtract(12, 'month').unix(),
    to: moment().unix(),
  };
  private preMarketLastUpdate: IMarketData = null;

  showEntryAndExitOptions: any = [];
  showTradeOptions: any = [];
  technicalIndicators: any = [];
  selectedSymbol: ISymbol = null;
  chartSaveData: IChartSaveData = {
    indicators: [],
    chartType: 0,
    timeZone: 'Etc/UTC',
    priceScaleMode: 0,
  };

  tradePosition: any = {};
  firstTradingStrategy: any = {};
  secondTradingStrategy: any = {};

  indicatorIds: Array<EntityId> = [];
  indicatorIdsWithAdjustablePrecision: Array<EntityId> = [];
  profitLossStudyIds: { firstId?: EntityId; secondId?: EntityId } = null;
  minStockPriceLineId: EntityId | null = null;
  tradeIds: Array<EntityId> = [];
  entryAndExitIds: Array<EntityId> = [];
  powerXStudyId: EntityId | null = null;
  preMarketStudyId: EntityId | null = null;

  screenWidth: number;
  screenHeight: number;
  desktopHeader = 201;
  mobileHeader = 451;
  maxChartHeight = 650;
  minIndicatorHeight = 92;

  Datafeed: IBasicDataFeed;

  supportedResolutions: any = [
    ChartIntervals.Daily,
    ChartIntervals.Daily2,
    ChartIntervals.Daily3,
    ChartIntervals.Weekly,
    ChartIntervals.Weekly3,
    ChartIntervals.Monthly,
    ChartIntervals.Monthly6,
  ];

  chartConfig: DatafeedConfiguration = {
    supported_resolutions: this.supportedResolutions,
    supports_marks: true,
    supports_timescale_marks: true,
  };

  private _subscriberData = {};

  constructor(
    private localStorageService: LocalStorageService,
    private tradingStrategiesService: TradingStrategiesService,
    private processedDataService: ProcessedDataService,
    private observableService: ObservableService,
    private tradeReportService: TradeReportService,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private pastPerformanceService: PastPerformanceService,
    private streamingService: StreamingService,
    private _exchangesService: ExchangesService,
    private tradingChartService: TradingChartService,
    private marketTimeService: MarketTimeService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {}

  async ngOnInit(): Promise<void> {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    const [chartSaveDataFromDB, selectedSymbol, firstTradingStrategy, secondTradingStrategy] = await Promise.all([
      this.userDataService.getAsJSON(ChartSaveData.PowerX),
      this.symbolsService.getById(this.observableService.symbol.getValue()),
      this.tradingStrategiesService.getById(this.observableService.firstTradingStrategyId.getValue()),
      this.tradingStrategiesService.getById(this.observableService.secondTradingStrategyId.getValue()),
    ]);
    this.selectedSymbol = selectedSymbol;
    this.tradePosition = this.observableService.tradePosition.getValue();
    this.firstTradingStrategy = firstTradingStrategy;
    this.secondTradingStrategy = secondTradingStrategy;
    this.chartSaveData = chartSaveDataFromDB || {};
    this._exchange = await this._exchangesService.getById(this.selectedSymbol?.exchange_id);
    this.technicalIndicators = Object.values(TechnicalIndicators);
    this.showEntryAndExitOption = this.observableService.showEntryAndExitOption.getValue();
    this.showTradeOption = this.observableService.showTradeOption.getValue();

    this.updateShowEntryAndExitOptions();
    this.updateShowTradeOptions();

    this.showTechnicalIndicatorsOption = this.observableService.showTechnicalIndicators.getValue();

    this.subscriber.add(
      this.observableService.firstTradingStrategyId.subscribe(async (firstTradingStrategyId) => {
        const updateEntryAndExit =
          this.showEntryAndExitOption === getShowEntryAndExitOptionName(this.firstTradingStrategy);
        const updateTradeOption = this.showTradeOption === getShowTradeOptionName(this.firstTradingStrategy);

        this.firstTradingStrategy = await this.tradingStrategiesService.getById(firstTradingStrategyId);

        const updatePromises = [this.showTrades(), this.showEntryAndExists()];
        if (updateEntryAndExit) {
          this.showEntryAndExitOption = getShowEntryAndExitOptionName(this.firstTradingStrategy);
          updatePromises.push(
            this.userDataService.set(
              UserSettings.ShowEntryAndExitOption,
              getShowEntryAndExitOptionName(this.firstTradingStrategy),
            ),
          );
        }
        if (updateTradeOption) {
          this.showTradeOption = getShowTradeOptionName(this.firstTradingStrategy);
          updatePromises.push(
            this.userDataService.set(UserSettings.ShowTradeOption, getShowTradeOptionName(this.firstTradingStrategy)),
          );
        }

        this.updateShowEntryAndExitOptions();
        this.updateShowTradeOptions();

        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);
        await Promise.all(updatePromises);
        await this.showProfitLossStudies();
        await this.showIndicators(this.selectedSymbol);
        if (this.activeChart) {
          await this.createCustomDropdown(this.showEntryAndExitOptions, 'Entry & Exits', 'showEntryAndExit');
          await this.createCustomDropdown(this.showTradeOptions, 'Show Trades', 'showTrade');
          await this.createCustomDropdown(this.technicalIndicators, 'Tech Ind.', 'showTechnicalIndicators');
        }
      }),
    );

    this.subscriber.add(
      this.observableService.secondTradingStrategyId.subscribe(async (secondTradingStrategyId) => {
        const updateEntryAndExit =
          this.showEntryAndExitOption === getShowEntryAndExitOptionName(this.secondTradingStrategy);
        const updateTradeOption = this.showTradeOption === getShowTradeOptionName(this.secondTradingStrategy);

        this.secondTradingStrategy = await this.tradingStrategiesService.getById(secondTradingStrategyId);

        const updatePromises = [this.showTrades(), this.showEntryAndExists()];
        if (updateEntryAndExit) {
          this.showEntryAndExitOption = getShowEntryAndExitOptionName(this.secondTradingStrategy);
          updatePromises.push(
            this.userDataService.set(
              UserSettings.ShowEntryAndExitOption,
              getShowEntryAndExitOptionName(this.secondTradingStrategy),
            ),
          );
        }
        if (updateTradeOption) {
          this.showTradeOption = getShowTradeOptionName(this.secondTradingStrategy);
          updatePromises.push(
            this.userDataService.set(UserSettings.ShowTradeOption, getShowTradeOptionName(this.secondTradingStrategy)),
          );
        }

        this.updateShowEntryAndExitOptions();
        this.updateShowTradeOptions();

        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);
        await Promise.all(updatePromises);
        await this.showProfitLossStudies();
        await this.showIndicators(this.selectedSymbol);

        if (this.activeChart) {
          await this.createCustomDropdown(this.showEntryAndExitOptions, 'Entry & Exits', 'showEntryAndExit');
          await this.createCustomDropdown(this.showTradeOptions, 'Show Trades', 'showTrade');
          await this.createCustomDropdown(this.technicalIndicators, 'Tech Ind.', 'showTechnicalIndicators');
        }
      }),
    );

    this.subscriber.add(
      this.observableService.showTechnicalIndicators.subscribe(async (showTechnicalIndicators) => {
        this.showTechnicalIndicatorsOption = showTechnicalIndicators;

        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);
        await this.showProfitLossStudies();
        await this.showIndicators(this.selectedSymbol);
      }),
    );

    this.subscriber.add(
      this.observableService.symbol.subscribe(async (symbolId) => {
        if (this.selectedSymbol?.security_id !== symbolId) {
          this.selectedSymbol = await this.symbolsService.getById(symbolId);
          this._exchange = await this._exchangesService.getById(this.selectedSymbol?.exchange_id);

          await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);

          if (!this.activeChart) {
            return;
          }

          this.listenToDrawEvent = false;
          await this.removeUserShapes();
          this.removeMinPriceLine();
          this.currentVisibleRange = this.activeChart.getVisibleRange();

          if (this.preMarketStudyId && this.activeChart) {
            this.activeChart.removeEntity(this.preMarketStudyId);
            this.preMarketStudyId = null;
          }

          this.activeChart.setSymbol(`${this.selectedSymbol.symbol}.${this.selectedSymbol.exchange_code}`, async () => {
            if (this.selectedSymbol?.country_code !== ExchangeCountriesCodes.CC) {
              this.showMinPriceLine();
            }

            await this.loadAndRestoreUserShapes(this.selectedSymbol.security_id);
            await this.showTrades();
            await this.showEntryAndExists();
            this.setIndicatorsPrecision();
          });
        }
      }),
    );

    this.subscriber.add(
      this.observableService.tradePosition.subscribe(async (tradePosition) => {
        this.tradePosition = tradePosition;
        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);

        await this.showProfitLossStudies();
        await this.showIndicators(this.selectedSymbol);
        await this.showTrades();
        await this.showEntryAndExists();
      }),
    );

    this.subscriber.add(
      this.observableService.showTradeOption.subscribe(async (showTradeOption) => {
        this.showTradeOption = showTradeOption;

        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);

        await this.showTrades();
        await this.showEntryAndExists();
      }),
    );

    this.subscriber.add(
      this.observableService.showEntryAndExitOption.subscribe(async (showEntryAndExitOption) => {
        this.showEntryAndExitOption = showEntryAndExitOption;

        await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);
        await this.showEntryAndExists();
      }),
    );

    this.subscriber.add(
      this.saveChartShapesSubject.subscribe(({ symbolId, state: { shapes, isError } }) => {
        const stateToSave = shapes.length > 0 ? shapes : null;

        if (_.isEqual(this.lastSavedShapesState, stateToSave)) {
          return;
        }

        if (isError) {
          // run onMicrotaskEmpty and inside setTimeout
          // to avoid specific for combination of chart and snackBar bug
          this.ngZone.onMicrotaskEmpty.pipe(take(1), delay(200)).subscribe(() => {
            this.showPopUpOnError();
          });
        }

        this.lastSavedShapesState = stateToSave;

        this.userDataService.set(this.getChartKey(symbolId), stateToSave);
      }),
    );

    await this.loadChart();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.observableService.isTradingChartInitialize$.next(false);
  }

  updateShowEntryAndExitOptions(): void {
    this.showEntryAndExitOptions = [];
    this.showEntryAndExitOptions.push(getShowEntryAndExitOptionName(this.firstTradingStrategy));
    this.showEntryAndExitOptions.push(getShowEntryAndExitOptionName(this.secondTradingStrategy));
    this.showEntryAndExitOptions.push(getShowEntryAndExitOptionName(ShowEntryAndExitOptions.Both));
    this.showEntryAndExitOptions.push(getShowEntryAndExitOptionName(ShowEntryAndExitOptions.None));
  }

  updateShowTradeOptions(): void {
    this.showTradeOptions = [];
    this.showTradeOptions.push(getShowTradeOptionName(this.firstTradingStrategy));
    this.showTradeOptions.push(getShowTradeOptionName(this.secondTradingStrategy));
    this.showTradeOptions.push(getShowTradeOptionName(ShowTradeOptions.None));
  }

  async loadChart(): Promise<void> {
    this.dataFeedMethod();

    const localStorageService = this.localStorageService;
    const observableService = this.observableService;
    const getProcessedDataStorageKey = (): string =>
      `${StorageKeys.ProcessedData}_${observableService.symbol.getValue()}`;

    await this.loadSymbolData(this.selectedSymbol, this.firstTradingStrategy, this.secondTradingStrategy);

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: `${this.selectedSymbol.symbol}.${this.selectedSymbol.exchange_code}`,
      datafeed: this.Datafeed,
      interval: ChartIntervals.Daily as ResolutionString,
      container: 'tv_chart_container',
      library_path: './assets/charting_library/',
      locale: 'en',
      auto_save_delay: 0.02,
      disabled_features: [
        'header_screenshot',
        'header_symbol_search',
        'header_undo_redo',
        'header_settings',
        'header_saveload',
        'header_resolutions',
        'header_compare',
        'control_bar',
        // TODO verify disabled features are actually disabled
        'show_hide_button_in_legend',
        'format_button_in_legend',
        'legend_context_menu',
        'main_series_scale_menu',
        // 'header-toolbar-intervals',
        'pane_context_menu',
        'header_fullscreen_button',
        'scales_context_menu',
        'display_market_status',
        'symbol_info',
        'symbol_search_hot_key',
        'show_object_tree',
        'go_to_date',
        'create_volume_indicator_by_default',
        'create_volume_indicator_by_default_once',
      ],
      enabled_features: [
        'chart_style_hilo',
        'charting_library_debug_mode',
        'left_toolbar',
        'use_localstorage_for_settings',
      ],
      drawings_access: {
        type: 'black',
        tools: [
          { name: 'Brush', grayed: false },
          { name: 'Anchored Text', grayed: false },
          { name: 'Anchored Note', grayed: false },
          { name: 'Double Curve', grayed: false },
          { name: 'Curve', grayed: false },
          { name: 'Fib Speed Resistance Arcs', grayed: false },
          { name: 'Highlighter', grayed: false },
          { name: 'Path', grayed: false },
        ],
      },
      time_frames: [
        { text: '5d', resolution: '1D' as ResolutionString, description: '5 Days', title: '5D' },
        { text: '1m', resolution: '1D' as ResolutionString, description: '1 Month', title: '1M' },
        { text: '3m', resolution: '1D' as ResolutionString, description: '3 Months', title: '3M' },
        { text: '6m', resolution: '1D' as ResolutionString, description: '6 Months', title: '6M' },
        { text: '1y', resolution: '1D' as ResolutionString, description: '1 Year', title: '1Y' },
        { text: '2y', resolution: '1D' as ResolutionString, description: '2 Years', title: '2Y' },
        { text: '5y', resolution: '1D' as ResolutionString, description: '5 Years', title: '5Y' },
      ],
      timezone: 'Etc/UTC',
      // charts_storage_url: this._chartsStorageUrl,
      // charts_storage_api_version: this._chartsStorageApiVersion,
      // client_id: this._clientId,
      // user_id: this._userId,
      fullscreen: false,
      autosize: true,
      debug: false,
      overrides: {
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showPrevClosePriceLine': false,
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.legendProperties.showSeriesTitle': false,
        'mainSeriesProperties.barStyle.thinBars': false,
      },
      custom_indicators_getter: (PineJS) => {
        return Promise.resolve([
          new Stochastic().createStochasticIndicator(
            PineJS,
            observableService,
            localStorageService,
            'StochasticIndicator',
            '#1',
          ),
          new Stochastic().createStochasticIndicator(
            PineJS,
            observableService,
            localStorageService,
            'StochasticIndicatorWithName',
            'Stochastic',
          ),
          new StochasticValue().createStochasticIndicator(PineJS, observableService, localStorageService),
          new Rsi().createRsiIndicator(PineJS, observableService, localStorageService, 'RsiIndicator', '#2'),
          new Rsi().createRsiIndicator(PineJS, observableService, localStorageService, 'RsiIndicatorWithName', 'RSI'),
          new RSIValue().createRsiIndicator(PineJS, observableService, localStorageService),
          new Macd().createMacdIndicator(PineJS, observableService, localStorageService, 'MacdIndicator', '#3'),
          new Macd().createMacdIndicator(
            PineJS,
            observableService,
            localStorageService,
            'MacdIndicatorWithName',
            'MACD',
          ),
          new MacdValue().createMacdIndicator(PineJS, observableService, localStorageService),
          new MacdSignal().createMacdSignalIndicator(PineJS, observableService, localStorageService),
          new ProfitLoss().createProfitLoss(PineJS, true, observableService, localStorageService),
          new ProfitLoss().createProfitLoss(PineJS, false, observableService, localStorageService),
          new BarColor(observableService).ploatBarColor(
            PineJS,
            'PowerX',
            'PowerX Strategy',
            getProcessedDataStorageKey,
            localStorageService,
          ),
        ]);
      },
    };

    try {
      this.tvWidget = new widget(widgetOptions);
      this.tvWidget.onChartReady(async () => {
        this.activeChart = this.tvWidget.activeChart();
        await this.loadAndRestoreUserShapes(this.selectedSymbol.security_id);
        await this.createCustomDropdown(this.showEntryAndExitOptions, 'Entry & Exits', 'showEntryAndExit');
        await this.createCustomDropdown(this.showTradeOptions, 'Show Trades', 'showTrade');
        await this.createCustomDropdown(this.technicalIndicators, 'Tech Ind.', 'showTechnicalIndicators');
        this.activeChart.setChartType(this.chartSaveData.chartType || 0);
        this.activeChart.getTimezoneApi().setTimezone(this.chartSaveData.timeZone || EasternTimeZoneName);

        if (this.powerXStudyId) {
          this.activeChart.removeEntity(this.powerXStudyId);
        }

        this.powerXStudyId = await this.activeChart.createStudy('PowerX', false, true);

        if (this.selectedSymbol?.country_code !== ExchangeCountriesCodes.CC) {
          this.showMinPriceLine();
        }
        await this.showProfitLossStudies();
        await this.showIndicators(this.selectedSymbol);
        await this.showTrades();
        await this.showEntryAndExists();
        this.tvWidget.applyOverrides({
          'mainSeriesProperties.priceAxisProperties.log': this.chartSaveData.priceScaleMode === 1,
          'mainSeriesProperties.priceAxisProperties.percentage': this.chartSaveData.priceScaleMode === 2,
        });

        await this.tradingChartService.restoreUserIndicators(this.chartSaveData, this.activeChart);

        // create volume indicator only once for current user
        const showVolumeIndicator = this.observableService.showVolumeIndicatorOnStartForPowerX.getValue();
        const isVolumeIndicatorInSavedData =
          this.chartSaveData?.indicators?.length > 0 &&
          this.chartSaveData.indicators.some((item) => item.name === 'Volume');
        if (showVolumeIndicator && !isVolumeIndicatorInSavedData) {
          await Promise.all([
            this.activeChart.createStudy('Volume', true, false),
            this.userDataService.set(UserSettings.ShowVolumeIndicatorOnStartForPowerX, false),
          ]);
        }

        this.tvWidget.subscribe('onAutoSaveNeeded', async () => {
          if (!this.listenToDrawEvent) {
            return;
          }

          const shapesFromMainPaneToSave = await this.getUserShapes();
          this.saveChartShapesSubject.next({
            symbolId: this.selectedSymbol.security_id,
            state: shapesFromMainPaneToSave,
          });

          await this.saveChartData();
        });

        this.subscriber.add(
          this.observableService.theme.subscribe(async (value) => {
            this.tvWidget.applyOverrides({
              'mainSeriesProperties.priceAxisProperties.log': this.chartSaveData.priceScaleMode === 1,
              'mainSeriesProperties.priceAxisProperties.percentage': this.chartSaveData.priceScaleMode === 2,
            });
            if (value === Themes.Dark) {
              this.tvWidget.changeTheme('Dark');
            } else if (value === Themes.Light) {
              this.tvWidget.changeTheme('Light');
            }
            if (this.powerXStudyId) {
              this.activeChart.removeEntity(this.powerXStudyId);
            }
            this.powerXStudyId = await this.activeChart.createStudy('PowerX', false, true);
          }),
        );

        this.setChartSize();
        this.observableService.isTradingChartInitialize$.next(true);
      });
    } catch (e) {
      console.warn(`Chart container initialization failed (${e.message})`);
    }
  }

  async createCustomDropdown(optionsList, title, optionName): Promise<void> {
    if (!optionsList || !optionsList.length) {
      return;
    }

    if (this[optionName + 'Dropdown']) {
      this[optionName + 'Dropdown'].remove();
    }
    const optionSuffix = optionsList === this.technicalIndicators ? '' : 'Option';
    const settingName =
      UserSettings[optionName[0].toUpperCase() + optionName.slice(1, optionName.length) + optionSuffix];
    const dropdownItems = optionsList.map((option) => {
      return {
        title: option,
        onSelect: async (): Promise<void> => {
          this[optionName + 'Option'] = option;
          await this.userDataService.set(settingName, option);
          this[optionName + 'Dropdown'].applyOptions({ title: title + ': ' + this[optionName + 'Option'] });
        },
      };
    });
    this[optionName + 'Dropdown'] = await this.tvWidget.createDropdown({
      title: title + ': ' + this[optionName + 'Option'],
      items: dropdownItems,
    });
  }

  async loadAndRestoreUserShapes(symbolId: number): Promise<void> {
    const shapesToRestore = await this.userDataService.getAsJSON(this.getChartKey(symbolId));
    let isNeedToChangeVisibleRange = false;
    if (!shapesToRestore) {
      this.lastSavedShapesState = null;
      this.listenToDrawEvent = true;

      return;
    }
    shapesToRestore.forEach((shape) => {
      shape.points.forEach((point) => {
        if (point.time < moment().subtract(12, 'month').unix()) {
          isNeedToChangeVisibleRange = true;
        }
      });
    });
    if (isNeedToChangeVisibleRange) {
      await this.activeChart.setVisibleRange({
        from: moment().subtract(60, 'month').unix(),
        to: moment().unix(),
      });
    }

    this.listenToDrawEvent = false;

    try {
      shapesToRestore.map((shape) => {
        if (shape.points && shape.points.length > 0) {
          this.activeChart.createMultipointShape(shape.points, shape.props);
        }
      });
    } catch (error) {
      console.warn({ message: 'Cannot restore saved PXO chart shapes', shapesToRestore, error });
      await this.removeUserShapes(); // discard possible changes form chart
    }

    this.listenToDrawEvent = true;
    const userShapesState = await this.getUserShapes();
    this.lastSavedShapesState = userShapesState.shapes;
    if (isNeedToChangeVisibleRange) {
      await this.activeChart.setVisibleRange(this.currentVisibleRange);
    }
  }

  async getUserShapes(): Promise<{ shapes: Array<Record<string, any>>; isError: boolean }> {
    if (!this.tvWidget || !this.activeChart) {
      return { shapes: [], isError: false };
    }

    const savedWidgetData = await new Promise<{ charts?: any[] }>((resolve) => {
      this.tvWidget.save((dataToSave) => resolve(dataToSave));
    });

    // there only one chart for now
    if (savedWidgetData.charts && savedWidgetData.charts[0]?.panes) {
      // another way is to check is pane.sources includes source with powerXStudyId
      const mainPane = savedWidgetData.charts[0].panes.find(
        (pane) => pane.mainSourceId === this.activeChart.getSeries().entityId(), // MainSeries
      );

      if (!mainPane) {
        return { shapes: [], isError: false };
      }

      // sources contain shapes and studies, use only shapes created by user
      const mainPaneSourceIDs = mainPane.sources.map((source) => source.id);
      const idsToExclude = this.getTechnicalShapeIDs();

      const shapes = this.activeChart
        .getAllShapes()
        .filter(({ id }) => !idsToExclude.includes(id) && mainPaneSourceIDs.includes(id))
        .map(({ name, id }) => {
          const shape = this.activeChart.getShapeById(id);

          return {
            points: shape.getPoints(),
            props: { id, shape: name, overrides: shape.getProperties() },
          };
        });

      const isError = !!shapes.find((item) => item.points.length === 0);

      return {
        shapes: shapes.filter((shape) => shape.points && shape.points.length > 0),
        isError,
      };
    }
  }

  async removeUserShapes(): Promise<void> {
    if (!this.activeChart) {
      return;
    }

    const idsToExclude = this.getTechnicalShapeIDs();

    this.activeChart
      .getAllShapes()
      .filter(({ id }) => !idsToExclude.includes(id))
      .forEach(({ id }) => {
        this.activeChart.removeEntity(id);
      });
  }

  private showPopUpOnError(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        icon: 'alert-error',
        message: 'Saving drawings failed. Please refresh the page and try again',
        snackbar: this.snackBar,
      },
      duration: 3000,
      horizontalPosition: 'left',
      panelClass: 'error',
    });
  }

  async saveChartData(): Promise<void> {
    if (!this.tvWidget || !this.activeChart) {
      return;
    }

    const currentChartData = {
      indicators: this.tradingChartService.getCurrentChartUserIndicators(this.activeChart),
      chartType: this.tvWidget.activeChart().chartType(),
      timeZone: this.tvWidget.activeChart().getTimezoneApi().getTimezone().id,
      priceScaleMode: this.tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0].getMode(),
    };

    if (this.isUserAddIndicators) {
      this.setChartSize();
    }
    this.chartSaveData = await this.tradingChartService.saveChartData(
      currentChartData,
      this.chartSaveData,
      ChartSaveData.PowerX,
      this.tvWidget,
    );
  }

  get isUserAddIndicators(): boolean {
    return !_.isEqual(
      this.tradingChartService.getCurrentChartUserIndicators(this.activeChart),
      this.chartSaveData.indicators,
    );
  }

  getTechnicalShapeIDs(): Array<EntityId> {
    return [this.minStockPriceLineId, ...this.entryAndExitIds, ...this.tradeIds];
  }

  getChartKey(symbolId: number): string {
    return `trading-drawings-${symbolId}`;
  }

  setChartSize(): void {
    if (!this.activeChart) {
      return;
    }
    const chartHeight =
      this.screenWidth > TabletMinWidth
        ? this.screenHeight - this.desktopHeader
        : this.screenHeight - this.mobileHeader;
    const panes = this.activeChart.getPanes();
    if (panes?.length && chartHeight >= this.maxChartHeight) {
      const allPanesHeight = [];
      panes.forEach((value, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        index === 0
          ? allPanesHeight.push(chartHeight - panes.length * this.minIndicatorHeight)
          : allPanesHeight.push(this.minIndicatorHeight);
      });
      this.activeChart.setAllPanesHeight(allPanesHeight);
    }
  }

  async loadSymbolData(symbol: ISymbol, firstTradingStrategy, secondTradingStrategy): Promise<void> {
    await Promise.all([
      this.processedDataService.get(symbol.security_id),
      this.tradeReportService.get(symbol.security_id, firstTradingStrategy?.id, false),
      this.tradeReportService.get(symbol.security_id, secondTradingStrategy?.id, false),
    ]);
  }

  setIndicatorsPrecision(): void {
    if (!this.activeChart) {
      return;
    }

    const precision = this.selectedSymbol?.exchange_code === ExchangeCountriesCodes.CC ? 4 : 2;

    for (const indicatorId of this.indicatorIdsWithAdjustablePrecision) {
      this.activeChart.getStudyById(indicatorId).applyOverrides({ precision });
    }
  }

  removeIndicators(): void {
    if (!this.activeChart) {
      return;
    }

    while (this.indicatorIds.length) {
      this.activeChart.removeEntity(this.indicatorIds.pop());
    }

    this.indicatorIdsWithAdjustablePrecision = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async showIndicators(symbol: ISymbol): Promise<void> {
    if (!this.activeChart) {
      return;
    }

    this.removeIndicators();
    let indicators = [];
    let indicatorIdsWithAdjustablePrecision = [];

    if (this.showTechnicalIndicatorsOption === TechnicalIndicators.Show) {
      const [stochasticId, rsiId, macdId] = await Promise.all([
        this.activeChart.createStudy('StochasticIndicatorWithName', false, true),
        this.activeChart.createStudy('RsiIndicatorWithName', false, true),
        this.activeChart.createStudy('MacdIndicatorWithName', false, true),
      ]);

      indicators = indicators.concat([stochasticId, rsiId, macdId]);
      indicatorIdsWithAdjustablePrecision.push(macdId);
    }

    if (this.showTechnicalIndicatorsOption === TechnicalIndicators.ShowValues) {
      const [stochasticId, stochasticValueId, rsiId, rsiValue, macdId, macdSignalIndicator, macdValue] =
        await Promise.all([
          this.activeChart.createStudy('StochasticIndicator', false, true),
          this.activeChart.createStudy('StochasticValueIndicator', false, true),
          this.activeChart.createStudy('RsiIndicator', false, true),
          this.activeChart.createStudy('RsiValueIndicator', false, true),
          this.activeChart.createStudy('MacdIndicator', false, true),
          this.activeChart.createStudy('MacdValueIndicator', false, true),
          this.activeChart.createStudy('MacdSignalIndicator', false, true),
        ]);

      this.activeChart.getStudyById(stochasticValueId).mergeUp();
      this.activeChart.getStudyById(stochasticValueId).changePriceScale('no-scale');
      this.activeChart.getStudyById(rsiValue).mergeUp();
      this.activeChart.getStudyById(rsiValue).changePriceScale('no-scale');
      this.activeChart.getStudyById(macdSignalIndicator).mergeUp();
      this.activeChart.getStudyById(macdSignalIndicator).changePriceScale('no-scale');
      this.activeChart.getStudyById(macdValue).mergeUp();
      this.activeChart.getStudyById(macdValue).changePriceScale(macdSignalIndicator);

      indicatorIdsWithAdjustablePrecision = indicatorIdsWithAdjustablePrecision.concat([
        macdId,
        macdValue,
        macdSignalIndicator,
      ]);
      indicators = indicators.concat([
        stochasticId,
        stochasticValueId,
        rsiId,
        rsiValue,
        macdId,
        macdValue,
        macdSignalIndicator,
      ]);
    }

    // if indicatorIds get previous indicators, remove them. Appears in case when user quickly switch symbols
    if (this.indicatorIds) {
      this.removeIndicators();
    }

    // save new indicators ids
    this.indicatorIds = this.indicatorIds.concat(indicators);
    this.indicatorIdsWithAdjustablePrecision = indicatorIdsWithAdjustablePrecision;

    this.setChartSize();
    this.setIndicatorsPrecision();
  }

  removeProfitLossCharts(): void {
    if (!this.activeChart) {
      return;
    }

    if (this.profitLossStudyIds) {
      this.activeChart.removeEntity(this.profitLossStudyIds.firstId);
      this.activeChart.removeEntity(this.profitLossStudyIds.secondId);
      this.profitLossStudyIds = null;
    }
  }

  async showProfitLossStudies(): Promise<void> {
    if (!this.activeChart) {
      return;
    }

    this.removeProfitLossCharts();

    this.profitLossStudyIds = {};
    this.profitLossStudyIds.firstId = await this.activeChart.createStudy('FirstProfitLoss', false, true);
    this.profitLossStudyIds.secondId = await this.activeChart.createStudy('SecondProfitLoss', false, true);
  }

  showMinPriceLine(): void {
    if (!this.activeChart) {
      return;
    }

    this.removeMinPriceLine();

    this.minStockPriceLineId = this.activeChart.createMultipointShape(
      [{ time: moment().unix().valueOf(), price: MinStockPriceLevel }],
      {
        shape: 'horizontal_line',
        lock: true,
        overrides: {
          linecolor: Colors.Yellow,
          textcolor: Colors.White,
          linewidth: 1,
        },
        disableSelection: true,
        disableUndo: true,
        disableSave: true,
      },
    );
  }

  removeMinPriceLine(): void {
    if (this.activeChart && this.minStockPriceLineId) {
      this.activeChart.removeEntity(this.minStockPriceLineId);
    }
  }

  removeTrades(): void {
    if (!this.activeChart) {
      return;
    }

    this.tradeIds.forEach((tradeId) => {
      this.activeChart.removeEntity(tradeId);
    });
  }

  async showTrades(): Promise<void> {
    if (!this.activeChart) {
      return;
    }

    this.removeTrades();

    if (this.showTradeOption === ShowTradeOptions.None) {
      return;
    }

    const tradingStrategy =
      this.showTradeOption === getShowTradeOptionName(this.firstTradingStrategy)
        ? this.firstTradingStrategy
        : this.secondTradingStrategy;

    const trades = await this.tradeReportService.get(this.selectedSymbol?.security_id, tradingStrategy.id);
    const chartData = await this.activeChart.exportData();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < chartData.data.length; i++) {
      const entryBar = chartData.data[i];
      const barDate = moment
        .unix(entryBar[0])
        .tz(this.chartSaveData.timeZone || EasternTimeZoneName)
        .format('YYYY-MM-DD');
      const trade = trades[barDate];

      const isMatch =
        trade && (this.tradePosition === TradePositions.LongAndShort || trade.position === this.tradePosition);
      if (!isMatch) {
        continue;
      }

      const tradeEntryChartObject = getTradeEntryChartObject(trade, entryBar);
      this.tradeIds.push(
        await this.activeChart.createMultipointShape(
          [
            {
              time: tradeEntryChartObject.time,
              price: tradeEntryChartObject.price,
            },
          ],
          {
            shape: 'icon',
            overrides: {
              icon: tradeEntryChartObject.icon,
              size: 13,
              color: tradeEntryChartObject.color,
              linewidth: 1,
            },
            lock: true,
            zOrder: tradeEntryChartObject.zOrder,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
          } as any,
        ),
      );

      if (trade.bar_exit_date) {
        const exitBar = chartData.data.find(
          (e) =>
            moment
              .unix(e[0])
              .tz(this.chartSaveData.timeZone || EasternTimeZoneName)
              .format('YYYY-MM-DD') === trade.bar_exit_date,
        );
        if (!exitBar) {
          continue;
        }

        const tradeExitChartObject = getTradeExitChartObject(trade, exitBar);
        this.tradeIds.push(
          await this.activeChart.createMultipointShape(
            [
              {
                time: tradeExitChartObject.time,
                price: tradeExitChartObject.price,
              },
            ],
            {
              shape: 'icon',
              overrides: {
                icon: tradeExitChartObject.icon,
                size: 13,
                color: tradeExitChartObject.color,
                linewidth: 1,
              },
              lock: true,
              zOrder: tradeExitChartObject.zOrder,
              disableSelection: true,
              disableSave: true,
              disableUndo: true,
            } as any,
          ),
        );
      }
    }
  }

  removeEntryAndExists(): void {
    if (!this.activeChart) {
      return;
    }

    this.entryAndExitIds.forEach((lineId) => {
      this.activeChart.removeEntity(lineId);
    });

    this.entryAndExitIds = [];
  }

  async showEntryAndExists(): Promise<void> {
    this.removeEntryAndExists();

    if (this.showEntryAndExitOption === ShowEntryAndExitOptions.None) {
      return;
    }

    const [firstSignal, secondSignal, firstTrade, secondTrade] = await Promise.all([
      this.pastPerformanceService.get(this.selectedSymbol?.security_id, this.firstTradingStrategy.id),
      this.pastPerformanceService.get(this.selectedSymbol?.security_id, this.secondTradingStrategy.id),
      this.tradeReportService.getRecent(
        this.selectedSymbol?.security_id,
        this.firstTradingStrategy.id,
        this.tradePosition,
      ),
      this.tradeReportService.getRecent(
        this.selectedSymbol?.security_id,
        this.secondTradingStrategy.id,
        this.tradePosition,
      ),
    ]);

    if (this.entryAndExitIds) {
      this.removeEntryAndExists();
    }

    const entries = [];
    const targetProfits = [];
    const stopLosses = [];

    const trades = [];

    if (
      (firstSignal || firstTrade) &&
      (this.showEntryAndExitOption === ShowEntryAndExitOptions.Both ||
        this.showEntryAndExitOption === getShowEntryAndExitOptionName(this.firstTradingStrategy)) &&
      (firstSignal.signal === 'BTO' ||
        firstSignal.signal === 'STO' ||
        ((firstSignal?.position || firstTrade?.position) &&
          firstSignal.signal !== 'BTC' &&
          firstSignal.signal !== 'STC'))
    ) {
      trades.push(firstSignal || firstTrade);
    }

    if (
      (secondSignal || secondTrade) &&
      (this.showEntryAndExitOption === ShowEntryAndExitOptions.Both ||
        this.showEntryAndExitOption === getShowEntryAndExitOptionName(this.secondTradingStrategy)) &&
      (secondSignal.signal === 'BTO' ||
        secondSignal.signal === 'STO' ||
        ((secondSignal?.position || secondTrade?.position) &&
          secondSignal.signal !== 'BTC' &&
          secondSignal.signal !== 'STC'))
    ) {
      trades.push(secondSignal || secondTrade);
    }

    trades.forEach((trade) => {
      if (!entries.find((e) => e === trade.signal_entry_price || e === trade.entry_price)) {
        entries.push(trade.signal_entry_price || trade.entry_price);
      }
      if (!targetProfits.find((e) => e === trade.signal_target_profit || e === trade.target_profit)) {
        targetProfits.push(trade.signal_target_profit || trade.target_profit);
      }
      if (!stopLosses.find((e) => e === trade.signal_stop_loss || e === trade.stop_loss)) {
        stopLosses.push(trade.signal_stop_loss || trade.stop_loss);
      }
    });

    entries.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.BondiBlue)));
    targetProfits.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.ForestGreen)));
    stopLosses.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.Pink)));
  }

  addHorizontalLineToChart(price, color): EntityId {
    if (!this.activeChart) {
      return;
    }

    return this.activeChart.createMultipointShape([{ time: moment().unix().valueOf(), price }], {
      shape: 'horizontal_line',
      lock: true,
      overrides: {
        textcolor: Colors.White,
        linecolor: color,
        linestyle: 2,
        linewidth: 1,
      },
      disableUndo: true,
      disableSelection: true,
      disableSave: true,
    });
  }

  createPreMarketDataCallback(onRealtimeCallback) {
    const hasPreMarketData = this.selectedSymbol?.country_code === ExchangeCountriesCodes.US;
    const isCrypto = this.selectedSymbol?.country_code === ExchangeCountriesCodes.CC;
    return (data): void => {
      if (!this.activeChart || data.symbol !== this.selectedSymbol?.symbol) {
        return;
      }

      if (this.preMarketStudyId && !this.marketTimeService.isPrePostMarketTime()) {
        this.activeChart.removeEntity(this.preMarketStudyId);
        this.preMarketStudyId = null;
      }

      if (
        hasPreMarketData &&
        data &&
        (data.isPreMarket || data.isPostMarket) &&
        this.marketTimeService.isPrePostMarketTime()
      ) {
        if (!this.preMarketStudyId) {
          this.preMarketStudyId = this.activeChart.createMultipointShape([{ time: data.time, price: data.close }], {
            shape: 'horizontal_line',
            lock: true,
            zOrder: 'top',
            overrides: {
              linecolor: '#FB8D00',
              textcolor: '#FB8D00',
              linestyle: 1,
              linewidth: 1,
              showLabel: true,
              horzLabelsAlign: 'right',
            },
            disableSelection: true,
            disableUndo: true,
            disableSave: true,
            text: this.marketTimeService.isPostMarketTime() ? 'Post' : 'Pre',
          });
        } else {
          const preMarket = this.activeChart.getShapeById(this.preMarketStudyId);
          const isDataChanged =
            this.preMarketLastUpdate?.symbol !== data.symbol ||
            this.preMarketLastUpdate?.time !== data.time ||
            this.preMarketLastUpdate?.close !== data.close;

          if (preMarket && isDataChanged) {
            preMarket.setPoints([{ time: data.time, price: data.close }]);
            this.preMarketLastUpdate = {
              symbol: data.symbol,
              close: data.close,
              time: data.time,
              isWeekend: data.isWeekend,
              isHoliday: data.isHoliday,
              isPreMarket: data.isPreMarket,
              isPostMarket: data.isPostMarket,
            };
          }
        }
      }

      if (
        data &&
        !data.isPreMarket &&
        !data.isPostMarket &&
        (isCrypto || !this.marketTimeService.isBeforeMarketTime())
      ) {
        onRealtimeCallback(data);
      }
    };
  }

  dataFeedMethod(): void {
    this.Datafeed = {
      onReady: (cb): void => {
        setTimeout(() => {
          cb(this.chartConfig);
        }, 0);
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback): void => {},

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback): void => {
        const symbolConfiguration: any = {
          name: `${this.selectedSymbol.symbol}.${this.selectedSymbol.exchange_code}`,
          full_name: this.selectedSymbol.description,
          description: this.selectedSymbol.description,
          type: 'stock',
          session: '24x7',
          timezone: 'America/New_York',
          ticker: `${this.selectedSymbol.symbol}.${this.selectedSymbol.exchange_code}`,
          data_status: 'pulsed',
          exchange: this.selectedSymbol.exchange_name,
          minmov: 1,
          pricescale: this._exchange?.is_round_price ? 100 : 10000,
          has_intraday: true,
          intraday_multipliers: ['1', '60'],
          supported_resolutions: this.supportedResolutions,
          volume_precision: 8,
          listed_exchange: 'listed_exchange',
        };

        setTimeout(() => {
          onSymbolResolvedCallback(symbolConfiguration);
        }, 0);
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getBars: async (symbolInfo, resolution: any, periodParams, onHistoryCallback, onErrorCallback): Promise<void> => {
        const bars = await this.tradingChartService.getDataForBars(
          this.selectedSymbol,
          periodParams,
          ChartHistoryIntervalMonths,
        );
        onHistoryCallback(bars, { noData: bars.length === 0 });
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getTimescaleMarks: async (symbolInfo, from, to, onDataCallback, resolution): Promise<void> => {
        const markers = await this.tradingChartService.getDataForTimescaleMarks(
          this.selectedSymbol,
          this.chartSaveData.timeZone || EasternTimeZoneName,
          ChartHistoryIntervalMonths,
        );
        await this.showTrades();
        onDataCallback(markers);
      },

      subscribeBars: async (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onResetCacheNeededCallback,
      ): Promise<void> => {
        this._subscriberData[subscribeUID] = { ...this.selectedSymbol };
        const processedData = await this.processedDataService.get(this.selectedSymbol.security_id);
        this.streamingService.subscribe(
          subscribeUID,
          { ...this.selectedSymbol },
          processedData,
          this.createPreMarketDataCallback(onRealtimeCallback),
        );
      },

      unsubscribeBars: (subscriberUID): void => {
        if (this._subscriberData[subscriberUID]) {
          this.streamingService.unsubscribe(subscriberUID, this._subscriberData[subscriberUID]);
          delete this._subscriberData[subscriberUID];
        }
      },
    };
  }
}
