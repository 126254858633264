import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScannerResultTypes, WatchlistType } from '@const';
import { ObservableService } from '@s/observable.service';
import { ScannerResultsService } from '@s/scanner-results.service';
import { SymbolsService } from '@s/symbols.service';
import { WatchlistDataService } from '@s/watchlist-data.service';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.csv';

@Component({
  selector: 'app-import-export-symbols-to-watchlist-modal',
  templateUrl: './import-export-symbols-to-watchlist-modal.component.html',
  styleUrls: ['./import-export-symbols-to-watchlist-modal.component.css'],
})
export class ImportExportSymbolsToWatchlistModalComponent implements OnInit {
  importFileForm: UntypedFormGroup = this.formBuilder.group({
    symbolFile: ['', Validators.required],
  });
  exportFileForm: UntypedFormGroup = this.formBuilder.group({
    fileName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9- ]+')]],
  });
  submitted = false;
  isImport = false; // if it is not import, it is export
  modalTitle: string;
  importFileData: any;
  watchListSymbols: any;
  fileValue: any = 'No file chosen';
  allSymbol = [];

  fileError: { [key: string]: boolean | any[]; inValidSymbols: any[] } = {
    symbolLength: false,
    noSymbol: false,
    inValidSymbolsError: false,
    confirmSubmittion: false,
    inValidSymbols: [],
  };

  get filenameErrorText() {
    const control = this.exportFileForm.get('fileName');
    const errors = control?.errors || {};

    if (!Object.keys(errors).length) {
      return '';
    }
    if (control.hasError('required')) {
      return 'You must enter a File name';
    }
    if (control.hasError('maxlength')) {
      return 'The maximum length of the file name is 30 characters';
    }
    return 'Please enter valid filename';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImportExportSymbolsToWatchlistModalComponent>,
    public observableService: ObservableService,
    private formBuilder: UntypedFormBuilder,
    private scannerResultsService: ScannerResultsService,
    private watchlistDataService: WatchlistDataService,
    private _symbolsService: SymbolsService,
  ) {}

  ngOnInit(): void {
    if (this.data !== undefined) {
      if (this.data.status === 'import') {
        this.modalTitle = 'Import Symbols List';
        this.isImport = true;
      } else {
        this.modalTitle = 'Export Symbols file';
        this.isImport = false;
      }
    }

    const mydate = moment(new Date()).format('YYYY-MM-DD');
    let fileName = '';
    if (this.data.windowName === 'Scanner Result') {
      fileName = 'scanner-result-' + mydate;
    } else {
      fileName = 'watchlist-' + moment(new Date()).format('YYYY-MM-DD-hh-mm-ss');
    }
    this.exportFileForm.controls.fileName.setValue(fileName);
  }

  // File get on change event
  openFile(event) {
    this.importFileData = event.target;
    this.fileValue = this.importFileForm.value.symbolFile;

    this.fileValue = this.fileValue.substring(this.fileValue.lastIndexOf('\\') + 1, this.fileValue.length);
    this.fileError.validFile = this.fileValue.split('.').pop() === 'txt' ? false : true;
    if (this.fileError.validFile) {
      this.modalTitle = 'Importing Error';
    }
  }

  public async importFileSubmit() {
    if (!this.importFileForm.valid) {
      return;
    }
    const symbols: string[] = await this.getSymbolsFromFile();
    if (symbols.length > 100) {
      this.fileError.symbolLength = true;
      this.submitted = false;
      return;
    }
    this.submitted = true;
    if (symbols.length) {
      const absentSymbols = await this.getAbsentSymbols(symbols);
      this.modalTitle = 'Importing Status';
      this.allSymbol = symbols.filter((el) => !absentSymbols.some((absentEl) => absentEl === el));
      if (absentSymbols.length) {
        this.fileError.inValidSymbolsError = true;
        this.fileError.inValidSymbols = absentSymbols;
      } else {
        await this.displayConfirmBox();
      }
    } else {
      this.fileError.noSymbol = true;
      this.modalTitle = 'Importing Error';
      this.submitted = false;
    }
  }

  private async getSymbolsFromFile(): Promise<string[]> {
    const reader = new FileReader();
    const res: Promise<string[]> = new Promise((resolve, reject) => {
      reader.onload = () => {
        try {
          const text = (reader.result as string).replace(/(\r\n|\n|\r)/gm, ',');
          const removeSpaceArr = text.split(',');

          // remove space from the text file
          const result = [];
          removeSpaceArr.forEach((element) => {
            if (element !== '') {
              result.push(element);
            }
          });
          resolve(result);
        } catch (e) {
          reject(e);
        }
      };
    });
    reader.readAsText(this.importFileData.files[0]);
    return res;
  }

  async getAllSymbolsMap() {
    const allSymbols = await this._symbolsService.getAll();

    const allSymbolsMap = {};
    for (const symbol of allSymbols) {
      if (allSymbolsMap[symbol.symbol]) {
        allSymbolsMap[symbol.symbol].push(symbol.security_id);
      } else {
        allSymbolsMap[symbol.symbol] = [symbol.security_id];
      }
    }

    return allSymbolsMap;
  }

  async getAbsentSymbols(symbolsFromFile) {
    const allSymbolsMap = await this.getAllSymbolsMap();

    return symbolsFromFile.filter((s) => !allSymbolsMap[s]);
  }

  async displayConfirmBox() {
    this.modalTitle = 'Import Symbols List';
    this.fileError.inValidSymbolsError = false;
    if (this.allSymbol.length === this.fileError.inValidSymbols.length) {
      this.fileError.noSymbol = true;
    } else {
      const watchlist = await this.watchlistDataService.get(WatchlistType.PowerX);
      if (watchlist.length > 0) {
        this.fileError.confirmSubmittion = true;
      } else {
        await this.importSymbols('no');
      }
    }
  }

  async importSymbols(saveCurrent: string) {
    try {
      if (saveCurrent === 'yes') {
        const mydate = moment(new Date()).format('YYYY-MM-DD-hh-mm-ss');
        const fileName = 'saved-watchlist-symbols-' + mydate;
        await this.watchListExport(fileName);
      }
      const allSymbolsMap = await this.getAllSymbolsMap();
      const security_ids = this.allSymbol
        .map((s) => allSymbolsMap[s])
        .flat()
        .filter((s) => s);
      await this.watchlistDataService.batchInsert(
        security_ids.map((security_id) => ({
          security_id,
          type: WatchlistType.PowerX,
        })),
      );
      this.observableService.watchlistUpdated.next(true);

      this.dialogRef.close('success');
    } catch (error) {
      /* error handling can be here */
    }
  }

  // Function for Export file from watchlist
  async exportFileSubmit() {
    this.submitted = true;
    if (!this.exportFileForm.valid) {
      return;
    } else {
      if (this.data.windowName === 'Scanner Result') {
        const symbols = await this.scannerResultsService.get(ScannerResultTypes.Pxo);
        const result = symbols.map((el) => {
          return {
            Symbol: el.symbol,
            Company: el.company_name,
            Exchange: el.country_code,
          };
        });
        const fileName = this.exportFileForm.value.fileName;
        this.exportAsExcelFile(result, fileName);
      } else {
        const fileName = this.exportFileForm.value.fileName;
        this.watchListExport(fileName);
      }
    }
  }

  exportFileInputPress() {
    const fileName = this.exportFileForm.value.fileName.replace(/[^a-zA-Z0-9- ]/g, '');
    this.exportFileForm.controls.fileName.setValue(fileName);
  }

  closeModal() {
    this.dialogRef.close();
  }

  async watchListExport(fileName) {
    const watchlist = await this.watchlistDataService.get(WatchlistType.PowerX);
    const exported = {};
    const result = watchlist
      .map((el) => {
        if (!exported[el.company_name]) {
          exported[el.company_name] = true;
          return {
            Symbol: el.symbol,
            Company: el.company_name,
            Exchange: el.country_code,
          };
        }
      })
      .filter((s) => s);
    this.exportAsExcelFile(result, fileName);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    this.dialogRef.close('success');
  }

  setDefaultName(input) {
    const inputVal = this.exportFileForm.value[input];
    if (inputVal == null || inputVal === '') {
      this.exportFileForm = this.formBuilder.group({
        fileName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9- ]+')]],
      });

      // convert date as American timezone
      // const convertDateToUser = new Date().toLocaleString('en-US', {
      //   timeZone: 'America/New_York'
      // }).slice(0, 9);
      const convertDateToUser = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      });
      const mydate = moment(new Date()).format('YYYY-MM-DD');
      let fileName = '';
      if (this.data.windowName === 'Scanner Result') {
        fileName = 'scanner-result-' + mydate;
      } else {
        fileName = 'Watchlist-' + moment(new Date()).format('YYYY-MM-DD-hh-mm-ss');
      }
      // const fileName = 'Watchlist_' + convertDateToUser;
      this.exportFileForm.controls.fileName.setValue(fileName);
    }
  }
}
