
import { Subscription } from 'rxjs';
import { CAExchangesForGoogleSearch, EasternTimeZoneName, ExchangeCountriesCodes, TabletMinWidth, TradePositions, USExchangesForGoogleSearch, UserSettings, allExchangeCountryCodes, formatDecimal, formatNumber, getTradePositionName } from '@const';
import { DialogsService } from '@s/common';
import { ObservableService } from '@s/observable.service';
import { SearchPopupService } from '@s/search-popup.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PositionSizingModel } from '@mod/data/position-sizing.model';
import { PositionSizingPopupComponent } from '@m/common/position-sizing-popup/position-sizing-popup.component';
import { getRiskAmountPerTrade as getRiskAmountPerTradeImported } from '@u/business-logic-utils';
import { Platform } from '@angular/cdk/platform';
import { UserDataService } from '@s/user-data.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { JobDataService } from '@s/job-data.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { EarningsService } from '@s/earnings.service';
import moment from 'moment';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, signal } from '@angular/core';

@Component({
  selector: 'app-powerx',
  templateUrl: './powerx.component.html',
  styleUrls: ['./powerx.component.scss'],
})
export class PowerxComponent implements OnInit, AfterViewInit, OnDestroy {
  public showMaintenance = false;
  public width: number = window.innerWidth;
  protected userSetting: any = {};
  protected user = { first_name: '', last_name: 'undefined', theme: 1, show_mm_trades: 0, access_type: 1 };

  protected showOrderPanel = false;
  protected isLoading = signal(true);

  private subscriptions = new Subscription();
  protected formatNumber = formatNumber;
  protected formatDecimal = formatDecimal;
  protected accountSize: number;
  protected accountRiskPercent: number;
  protected isMobileOS;
  public isMobile = false;
  public mobileWidth = 992;
  private readonly platform: Platform;
  tradePosition: any = '';
  TradePositions = TradePositions;
  getTradePositionName = getTradePositionName;
  selectedSymbol: ISymbol = null;
  public isShowNewsButton = false;
  public exchangeCodeForGoogleSearch;
  selectedSymbolDetails: any = null;
  lastUpdated: any = '';
  nextEarnings: any = null;

  constructor(
    private dialogsService: DialogsService,
    private searchPopupService: SearchPopupService,
    private observableService: ObservableService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private jobDataService: JobDataService,
    private securityDataDetailsService: SecurityDataDetailsService,
    private earningsService: EarningsService,
  ) {}

  @HostListener('window:keyup', ['$event'])
  onCharsInput(e: KeyboardEvent): void {
    const element = e.target as HTMLElement;
    if (element && element.hasAttribute('data-ignore-search-popup')) {
      return;
    }

    const isSymbol = e.key?.length === 1 && !!e.key?.match(/[a-z]/i);
    if (isSymbol) {
      this.searchPopupService.openPopup(e.key, true, false, false, allExchangeCountryCodes);
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event): void {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
    this.handlePinnedOrderModal();
  }

  async ngOnInit(): Promise<void> {
    this.showMaintenance = this.observableService.showMaintenance.getValue();
    this.handleShowOrderPanel();
    this.checkIsMobileOS();

    this.subscriptions.add(
      this.observableService.showMaintenance.subscribe((showMaintenance) => (this.showMaintenance = showMaintenance)),
    );

    this.subscriptions.add(
      this.observableService.tradingPanelOrderModalPinned.subscribe(() => this.handleShowOrderPanel()),
    );

    this.subscriptions.add(
      this.observableService.tradingPanelOrderInput.subscribe((_) =>
        this.handleShowOrderPanel())
    );
    this.subscriptions.add(
      this.observableService.mySettings.subscribe((userSettings) => (this.user = userSettings)));

    this.subscriptions.add(this.observableService.accountSize.subscribe((accountSize) => (this.accountSize = accountSize)));

    this.subscriptions.add(this.observableService.tradePosition.subscribe((tradePosition) => (this.tradePosition = tradePosition)));

      this.accountSize = this.observableService.accountSize.getValue();
      this.accountRiskPercent = this.observableService.accountRiskPercent.getValue();

      const symbol = this.observableService.symbol.getValue();

      this.subscriptions.add(this.observableService.symbol.subscribe(async (newSymbol) => await this.loadData(newSymbol)));

    await this.loadData(symbol);
    
    this.subscriptions.add(this.observableService.tradingPanelOrderInput.subscribe(() => this.handleShowOrderPanel()));
  }

  ngAfterViewInit(): void {
    // need to show preloader for a some time
    setTimeout(() => {
      this.isLoading.set(false);
    }, 100);
  }

  ngOnDestroy(): void {
    this.observableService.tradeReportTradingStrategyId.next(null);
    this.subscriptions.unsubscribe();
  }

  get updateTime() {
    if (!this.lastUpdated?.updated_date) return 'N/A';
    return moment(this.lastUpdated?.updated_date).tz('America/New_York').format('MMM D, YYYY h:mm A [ET]');
  }

  get nextEarningsInDays() {
    return this.nextEarnings?.earnings_in_days;
  }

  get nextEarningsDate() {
    if (!this.nextEarnings) return null;
    const formatedDate = moment(this.nextEarnings.report_date).tz(EasternTimeZoneName).format('MMM D, YYYY');
    switch (this.nextEarnings.earnings_in_days) {
      case 0:
        return `Earnings Today on ${formatedDate} ET`;
      case 1:
        return `Earnings in 1 day on ${formatedDate} ET`;
      default:
        return `Earnings in ${this.nextEarnings.earnings_in_days} days on ${formatedDate} ET`;
    }
  }

  handleShowOrderPanel(): void {
    const isPinned = this.observableService.tradingPanelOrderModalPinned.getValue();
    const orderInput = this.observableService.tradingPanelOrderInput.getValue();

    this.showOrderPanel = isPinned && !!orderInput;
  }

  private handlePinnedOrderModal(): void {
    if (!this.showOrderPanel || this.width > TabletMinWidth) {
      return;
    }

    this.observableService.tradingPanelOrderModalPinned.next(false);

    const orderInput = this.observableService.tradingPanelOrderInput.getValue();
    this.dialogsService.openTradingPanelOrderDialog(orderInput);
  }

  protected positionSizing(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['position-sizing', 'modals'];
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      accountSizeSettingName: UserSettings.AccountSize,
      accountRiskPercentSettingName: UserSettings.AccountRiskPercent,
      accountRiskAmountSettingName: UserSettings.AccountRiskAmount,
      showCanadaWarning: true,
    } as Partial<PositionSizingModel>;

    this.dialog.open(PositionSizingPopupComponent, dialogConfig);

    document.getElementsByClassName('modals')[0].scrollTo(0, 0);
  }


  protected getRiskAmountPerTrade(): string {
    return formatNumber(getRiskAmountPerTradeImported(this.accountSize, this.accountRiskPercent));
  }

  protected async openPrintModal(): Promise<void> {

    await this.dialogsService.openPrintDataDialog((afterClosedData) => {
      if (afterClosedData) {
        this.observableService.isNeedPrint$.next(true);
      }
    });
  }

  protected checkIsMobileOS(): void {
    const isAndroid = this.platform?.ANDROID;
    const isIOS = this.platform?.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.isMobileOS = isAndroid || isIOS;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async updatePosition(tradePosition) {
    await this.userDataService.set(UserSettings.TradePosition, tradePosition);
  }


  async loadData(symbol): Promise<void> {
    const [selectedSymbol, selectedSymbolDetails, lastUpdated, nextEarnings] = await Promise.all([
      this.symbolsService.getById(symbol),
      this.securityDataDetailsService.get(symbol),
      this.jobDataService.getRecentSecurity(symbol),
      this.earningsService.getNext(symbol),
    ]);

    this.selectedSymbol = selectedSymbol;
    this.selectedSymbolDetails = selectedSymbolDetails;
    this.lastUpdated = lastUpdated;
    this.nextEarnings = nextEarnings;
    this.showNewsButton();
    this.getExchangeForGoogleSearch();
  }

  showNewsButton(): void {
    this.isShowNewsButton = this.selectedSymbol?.country_code === ExchangeCountriesCodes.US
      || this.selectedSymbol?.country_code === ExchangeCountriesCodes.CA
      && !!CAExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
  }

  getExchangeForGoogleSearch(): void {
    if (this.selectedSymbol?.country_code === ExchangeCountriesCodes.US) {
      this.exchangeCodeForGoogleSearch = USExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
    }
    if (this.selectedSymbol?.country_code === ExchangeCountriesCodes.CA) {
      this.exchangeCodeForGoogleSearch = CAExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
    }
  }

  openNews(): void {
    window.open(`https://www.google.com/finance/quote/${this.selectedSymbol?.symbol}:${this.exchangeCodeForGoogleSearch}`, '_blank');
  }
}
