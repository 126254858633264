<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  class="scanner-watchlist-tab-group"
  [selectedIndex]="selectedTabIndex"
  (selectedTabChange)="tabChanged($event)"
  animationDuration="200"
>
  <mat-tab>
    <ng-template mat-tab-label>
      Scanner Result
      <button mat-icon-button [matMenuTriggerFor]="scannerToggle" class="drop">
        <img [src]="arrowSvg" alt="open-menu-icon">
      </button>
      <mat-menu #scannerToggle="matMenu" class="ws-menu">
        <button mat-menu-item (click)="importExportSymbolFile('export','Scanner Result')">
          <img [src]="importSvg" alt="export-symbols-list-icon">
          Export symbol list
        </button>
      </mat-menu>
    </ng-template>
    <app-scanner-v2 [isActive]="selectedTabIndex === 0" _ngcontent-scanner></app-scanner-v2>
  </mat-tab>

  <mat-tab #watchlistTab>
    <ng-template mat-tab-label>
      Watchlist
      <button mat-icon-button [matMenuTriggerFor]="watchToggle" class="drop show-for-mobile">
        <img [src]="arrowSvg" alt="open-menu-icon">
      </button>
      <mat-menu #watchToggle="matMenu" class="ws-menu">
        <button
          mat-menu-item
          (click)="importExportSymbolFile('export','Watchlist')"
          class="hide-on-max-991"
        >
          <img [src]="importSvg" alt="export-symbols-list-icon">
          Export symbol list
        </button>
        <button
          mat-menu-item
          (click)="importExportSymbolFile('import','Watchlist')"
          class="hide-on-max-991"
        >
          <img [src]="exportSvg" alt="import-symbols-list-icon">
          Import symbol list
        </button>
        <button mat-menu-item (click)="addSectionIntoGroupedWatchlist()">
          <mat-icon [svgIcon]="'add-section'" class="middle-icon"></mat-icon>
          <span>Add section</span>
        </button>
      </mat-menu>
    </ng-template>
    <app-grouped-watchlist-wrapper
      #groupedWatchlistWrapper
      [isActive]="watchlistTab.isActive"
    ></app-grouped-watchlist-wrapper>
  </mat-tab>
</mat-tab-group>

<button mat-icon-button class="add-watchlist" *ngIf="selectedTabIndex == 1" (click)="addWatchListSymbol()">
  <img [src]="addSvg" alt="add-watchlist-icon">
</button>
