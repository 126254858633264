<app-maintenance></app-maintenance>

<div
  *ngIf="!showMaintenance"
  class="powerx-main scroll-style"
>
  <div class="main-menu">
    <h2 class="mobile-header">PowerX</h2>
    <div
      #searchBar
      class="search-bar"
    >
      <app-search-bar [isPowerX]="true"></app-search-bar>
    </div>

    <div class="company-name powerx-section overflow-ellipsis">
      <span
        *ngIf="selectedSymbol?.symbol"
        class="cap"
      >
        <span class="updated-selected-symbol"
          >Updated: <br />
          {{ updateTime }}</span
        >
      </span>
    </div>

    <div class="long-position">
      <button
        [ngClass]="tradePosition === TradePositions.LongAndShort ? 'grey' : ''"
        [matMenuTriggerFor]="menu"
        [class]="tradePosition"
        mat-button
        class="long-position-button"
      >
        {{ getTradePositionName(tradePosition) }}
      </button>
      <mat-menu
        #menu="matMenu"
        class="position-dropdown"
      >
        <ng-container
          *ngFor="let tPosition of [TradePositions.LongOnly, TradePositions.ShortOnly, TradePositions.LongAndShort]"
        >
          <button
            [ngStyle]="{ 'background-color': tradePosition === tPosition ? 'var(--selected-item-bg)' : '' }"
            (click)="updatePosition(tPosition)"
            mat-menu-item
          >
            {{ getTradePositionName(tPosition) }}
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <div
      (click)="positionSizing()"
      class="account-size"
    >
      <div class="size">
        <span>Account</span>
        <p>${{ formatNumber(accountSize) }}</p>
      </div>
      <div class="risk">
        <span>Risk</span>
        <div class="risk-inner-div">
          <p>{{ formatDecimal(accountRiskPercent, 1) }}%</p>
          <p>${{ getRiskAmountPerTrade() }}</p>
        </div>
      </div>
    </div>
    <div class="run-scanner-container">
      @if (!showMaintenance) {
        <app-run-scanner-button></app-run-scanner-button>
      }
    </div>

    <div class="print-data-container">
      @if (!isMobileOS && !showMaintenance) {
        <div class="print-data-group">
          <button
            (click)="openPrintModal()"
            mat-icon-button
            class="open-print-button"
          >
            <mat-icon
              [svgIcon]="'print'"
              class="print-icon"
            ></mat-icon>
          </button>
        </div>
      }
    </div>
  </div>
  <div
    [ngStyle]="{
      '--custom-width-with-pinned-order-panel': showOrderPanel ? '370px' : '0px',
      '--search-bar-height': searchBar.offsetHeight + 'px',
    }"
    class="left-side"
  >
    <app-chart-menu></app-chart-menu>
  </div>
  <app-trading-panel-order-panel *ngIf="showOrderPanel"></app-trading-panel-order-panel>
  <div class="right-side">
    <as-split
      direction="horizontal"
      class="all-section"
      gutterSize="4"
    >
      <as-split-area>
        <as-split
          direction="vertical"
          gutterSize="4"
        >
          <as-split-area
            [size]="35"
            minSize="25"
            class="right-one"
          >
            <app-scanner-watchlist-panel></app-scanner-watchlist-panel>
          </as-split-area>
          <as-split-area
            [size]="65"
            minSize="25"
            class="right-two"
            gutterSize="11"
            class="h-less-991"
          >
            <app-data-window [isPrint]="false"></app-data-window>
          </as-split-area>
        </as-split>
      </as-split-area>
    </as-split>
  </div>
</div>

<app-strategy-skeleton [isVisible]="isLoading()"></app-strategy-skeleton>
