import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSplitModule } from 'angular-split';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { GroupedWatchlistComponent } from '@c/grouped-watchlist/grouped-watchlist.component';
import { HeaderModule } from '@c/header/header.module';
import { MaintenanceModule } from '@c/maintenance/maintenance.module';
import { PortfolioComponent } from '@c/portfolio/portfolio.component';
import { ProfitAndLossChartModule } from '@c/profit-and-loss-chart/profit-and-loss-chart.module';
import { RunScannerButtonModule } from '@c/run-scanner-button/run-scanner-button.module';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { SimpleModalHeaderComponent } from '@c/simple-modal-header/simple-modal-header.component';
import { StrategySkeletonComponent } from '@c/strategy-skeleton/strategy-skeleton.component';
import { TradingPanelOrderPanelComponent } from '@c/trading-panel-order-panel/trading-panel-order-panel.component';
import { FocusedDirective } from '@core/directives/key-up-down';
import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { LinksOrganiserModule } from '@m/common/links-organiser/links-organiser.module';
import { ScannerV2Component } from '@m/powerx/scanner-watchlist-window/scanner-v2/scanner-v2.component';
import { HolidaysModalComponent } from '@mdl/holidays-modal/holidays-modal.component';
import { AppLoaderService } from '@s/app-loader.service';
import { DividendsService } from '@s/dividends.service';
import { EarningsService } from '@s/earnings.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { HolidaysService } from '@s/holidays.service';
import { JobDataService } from '@s/job-data.service';
import { LocalStorageService } from '@s/local-storage.service';
import { OptionDataService } from '@s/option-data.service';
import { PastPerformanceService } from '@s/past-performance.service';
import { ProcessedDataService } from '@s/processed-data.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { SymbolsService } from '@s/symbols.service';
import { TradeReportService } from '@s/trade-report.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { SharedModule } from '@sh/shared.module';
import { PositionBuyingPowerPopupComponent } from './common/position-buying-power-popup/position-buying-power-popup.component';
import { PositionSizingPopupComponent } from './common/position-sizing-popup/position-sizing-popup.component';
import { ScannerSettingPopupComponent } from './common/scanner-setting-popup/scanner-setting-popup.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { CurrentPositionSymbolComponent } from './powerx/data-window/current-position-symbol/current-position-symbol.component';
import { DataWindowComponent } from './powerx/data-window/data-window.component';
import { LongestTradeLengthComponent } from './powerx/data-window/longest-trade-length/longest-trade-length.component';
import { PositionComparePopupComponent } from './powerx/data-window/position-compare-popup/position-compare-popup.component';
import { RoiTotalProfitComponent } from './powerx/data-window/roi-total-profit/roi-total-profit.component';
import { WinProfitFactorComponent } from './powerx/data-window/win-profit-factor/win-profit-factor.component';
import { PowerxComponent } from './powerx/powerx.component';
import { GroupedWatchlistWrapperComponent } from './powerx/scanner-watchlist-window/grouped-watchlist-wrapper/grouped-watchlist-wrapper.component';
import { ImportExportSymbolsToWatchlistModalComponent } from './powerx/scanner-watchlist-window/import-export-symbols-to-watchlist-modal/import-export-symbols-to-watchlist-modal.component';
import { ScannerWatchlistPanelComponent } from './powerx/scanner-watchlist-window/scanner-watchlist-panel.component';
import { ScannerComponent } from './powerx/scanner-watchlist-window/scanner/scanner.component';
import { WatchlistComponent } from './powerx/scanner-watchlist-window/watchlist/watchlist.component';
import { ChartMenuComponent } from './powerx/tab-section/chart-menu.component';
import { OptionRecordComponent } from './powerx/tab-section/options/option-record/option-record.component';
import { OptionsComponent } from './powerx/tab-section/options/options.component';
import { TradingChartComponent } from './powerx/tab-section/trading-chart/trading-chart.component';
import { CompareProfitsComponent } from './powerx/tab-section/trading-report/compare-profits/compare-profits.component';
import { PrintProfitsComponent } from './powerx/tab-section/trading-report/print-profits/print-profits.component';
import {
  MobileTradingReportComponent,
  TradingReportComponent,
  WebTradingReportComponent,
} from './powerx/tab-section/trading-report/trading-report.component';

@NgModule({
  declarations: [
    FocusedDirective,
    ChartMenuComponent,
    DataWindowComponent,
    MainComponent,
    PositionComparePopupComponent,
    PositionBuyingPowerPopupComponent,
    PositionSizingPopupComponent,
    PowerxComponent,
    ScannerComponent,
    ScannerWatchlistPanelComponent,
    TradingChartComponent,
    TradingReportComponent,
    MobileTradingReportComponent,
    WebTradingReportComponent,
    OptionsComponent,
    OptionRecordComponent,
    WatchlistComponent,
    ScannerSettingPopupComponent,
    ImportExportSymbolsToWatchlistModalComponent,
    CurrentPositionSymbolComponent,
    RoiTotalProfitComponent,
    WinProfitFactorComponent,
    LongestTradeLengthComponent,
    SimpleModalHeaderComponent,
    CompareProfitsComponent,
    HolidaysModalComponent,
    PrintProfitsComponent,
    GroupedWatchlistWrapperComponent,
  ],
  imports: [
    SharedModule,
    AngularSplitModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MainRoutingModule,
    MainRoutingModule,
    ProfitAndLossChartModule,
    HeaderModule,
    LinksOrganiserModule,
    RunScannerButtonModule,
    PlatformModule,
    MaintenanceModule,
    SymbolFlagModule,
    TradingPanelOrderPanelComponent,
    PortfolioComponent,
    MatInputModule,
    GroupedWatchlistComponent,
    ResizeDirectiveModule,
    ScrollingModule,
    TableVirtualScrollModule,
    ScannerV2Component,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    StrategySkeletonComponent,
  ],
  providers: [
    DividendsService,
    EarningsService,
    HistoricalDataService,
    LocalStorageService,
    JobDataService,
    OptionDataService,
    PastPerformanceService,
    ProcessedDataService,
    SecurityDataDetailsService,
    SymbolsService,
    TradeReportService,
    TradingStrategiesService,
    AppLoaderService,
    HolidaysService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    provideNgxMask(),
  ],
  exports: [TradingReportComponent, DataWindowComponent, OptionsComponent, SimpleModalHeaderComponent],
})
export class MainV2Module {}
